let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.10.3/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "masonry": "https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js",
    "stickyfill": "https://cdn.jsdelivr.net/npm/stickyfilljs@2.1.0/dist/stickyfill.min.js",
    "datepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/datepicker.min.js",
    "datepicker_lang": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.min.js",
    "rellax": "https://cdn.jsdelivr.net/npm/rellax@1.10.0/rellax.min.js",
};