$.fn.lib_masonry = function () {
    let selector = $(this);

    $.importScript(cdnjs.masonry,function () {
        cssLoaded(function () {
            selector.each(function(){
                let elm = $(this);
                let ref = elm.closest("[data-ref-masonry]");
                let options = elm.data("lib-masonry");

                let horizontalOrder = (typeof options["horizontalOrder"] !== "undefined") ? options["horizontalOrder"] : true;
                let percentPosition = (typeof options["percentPosition"] !== "undefined") ? options["percentPosition"] : true;
                let fitWidth = false;
                let grid;

                if (typeof options["horizontalOrder"] === "undefined") {
                    if(win.width() <= 960) {
                        horizontalOrder = false;
                    }
                }

                if (options["fullWidth"] === true) {
                    if(win.width() > 1280) {
                        fitWidth = true;
                        percentPosition = false;
                        ref.addClass("size--full");
                        horizontalOrder = false;
                    }
                }

                if (!selector.data('masonry')) {
                    grid = elm.masonry({
                        itemSelector: (typeof options["itemSelector"] !== "undefined") ? options["itemSelector"] : ".col",
                        percentPosition: percentPosition,
                        columnWidth: (typeof options["columnWidth"] !== "undefined") ? options["columnWidth"] : ".col",
                        horizontalOrder: horizontalOrder,
                        fitWidth: fitWidth,
                    });
                } else {
                    elm.masonry('appended', elm.children().not("[style]"));
                }

                win.on("resize", debounce(function () {
                    fitWidth = false;
                    percentPosition = true;
                    horizontalOrder = true;
                    elm.css("width","auto");

                    grid.masonry({
                        percentPosition: percentPosition,
                        horizontalOrder: horizontalOrder,
                        fitWidth: fitWidth
                    });
                },150));
            });
        });
    });
};
