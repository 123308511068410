$.lui("part",".part_form_booking",function (selector) {
    selector.find(".type--datepicker").each(function () {
        let el = $(this);
        let inp = el.find(`input[type="text"]`);
        let hidden = el.find(`input[type="hidden"]`);
        let minDate = new Date();
        let maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

        $.importScript(cdnjs.datepicker,function () {
            $.importScript(cdnjs.datepicker_lang.replace(".cs.",`.${lang}.`),function () {
                inp.datepicker({
                    language: lang,
                    position: "top center",
                    minDate: minDate,
                    maxDate: maxDate,
                    startDate: minDate,
                    autoClose: true,
                    altField: hidden,
                    altFieldDateFormat: 'yyyy-mm-dd',
                    onSelect: function (fd,d,inst) {
                        if(el.is("[data-dp-arrival]")) {
                            let newDate = new Date(d);
                            let departure = selector.find("[data-dp-departure]").find(`input[type="text"]`);
                            newDate.setDate(newDate.getDate() + 1);
                            departure.data("datepicker").update('minDate', new Date(newDate));
                            departure.data("datepicker").selectDate(new Date(newDate));
                        }
                    },
                    onShow: function () {
                        doc.find(".datepickers-container").addClass("state--active");
                    },
                    onHide: function () {
                        doc.find(".datepickers-container").removeClass("state--active");
                    },
                }).data("datepicker");
            });
        })
    })
});