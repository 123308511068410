$.lui("comp",".comp_highlights",(selector) =>{
    function generateLines(){
        let cols = selector.find(".wrp_comp_body").children(".col");

        cols.each(function (i) {
            cols[i].classList.remove("is--last");
            if(i > 0) {
                let j = i -1;
                if(cols[j].offsetTop < cols[i].offsetTop) {
                    cols[j].classList.add("is--last");
                }
            }
        });
    }

    cssLoaded(()=>{
        generateLines();
    });

    win.on("resize",debounce(function () {
        generateLines();
    },250));


    let image_wrap = selector.find(".elm_comp_image");
    let images = image_wrap.find(".part_ui_image");
    let items = selector.find(".elm_body_item");
    items.one("mouseenter",function () {
        image_wrap.show();
    });
    items.each(function (i) {
        let el = $(this);
        el.on("mousemove",function (e) {
            let pageX = e.pageX;
            let pageY = e.pageY - selector[0].offsetTop;
            image_wrap[0].classList.add("state--active");
            images[i].classList.add("state--active");
            images[i].style.display = "block";
            image_wrap[0].style.transform = `translate(${pageX - (image_wrap.outerWidth() /2 )}px,${pageY - (image_wrap.outerHeight() / 2)}px)`
        });
        el.on("mouseleave",function () {
            images[i].classList.remove("state--active");
            image_wrap[0].classList.remove("state--active");
        })
    });
});