(function($) {
    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_autosize", "[data-lib-autosize]", function(selector) {
        $.importScript(cdnjs.autosize,function () {
            autosize($(selector));
        });
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_masonry","[data-lib-masonry]",function (selector) {
        selector.lib_masonry();
    });

    $.lui("lib_sticky",".position--sticky",function (selector) {
        if (document.documentElement.classList.toString().match(/ie/) !== null) {
            $.importScript(cdnjs.stickyfill, function () {
                Stickyfill.add(selector);
            })
        }
    });

    $.lui("lib_scroll", "[data-lib-scroll]", function(selector) {
        selector.on("click",()=>{
            let ref = selector.closest("[data-ref-scroll]");
            if(ref.length) {
                ref.next("section, div").anchor_anim(500);
            }
        });
    });

    $.lui("lib_list", "[data-lib-list]", function(selector) {
        if(selector.length > 1) {
            selector.wrapAll($(`<div class="comp_list_wrapper" data-lib-anchor-offset="#layout_header"><div class="container"></div></div>`));
            let links = {};
            let ul = $(`<ul class="position--sticky"></ul>`);
            selector.each(function (i) {
                let el = $(this);
                let id = el.data("lib-list").id;
                let li = $(`<li><a href="#${id}" class="elm_nav_item${i===0 ? " state--active" : ""}" data-lib-anchor="{'hash':true}" data-no-swup>${el.data("lib-list").heading}</a></li>`);
                ul.append(li[0]);
                links[id] = li.find("a");
            });
            doc.find(".comp_list_wrapper").children(".container").prepend($(`<nav class="elm_comp_nav"></nav>`).append(ul));

            win.on("scroll",debounce(function () {
                let top = win.scrollTop();
                $(selector.get().reverse()).each(function () {
                    let el  = $(this);
                    let offset = el.offset().top - 300;
                    if (top >= offset) {
                        let id = el.data("lib-list").id;
                        doc.find(".comp_list_wrapper").find(".elm_comp_nav").find(".state--active").removeClass("state--active");
                        let link = links[id];
                        link.addClass("state--active");
                        return false;
                    }
                });
            },250));

            let elm_nav = doc.find(".comp_list_wrapper").find(".elm_comp_nav");
            elm_nav.find("ul").on("mouseenter",function () {
                elm_nav.addClass("state--hover");
            }).on("mouseleave",function () {
                elm_nav.removeClass("state--hover");
            });
        }
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('change', '[name="sort"]', function (e) {
            e.preventDefault();
            let frm = $(this.form);
            let sort = $('[name="sort"]').val();

            console.log(sort);
            console.log(frm.attr('method'));
            console.log(frm.attr('action'));

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: {sort: sort, page: 1},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {

                });
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    setTimeout(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);

    $(document).on('click', '[data-dialog-close-cookie]', function() {
        console.log('xxx');
        $.ajax({
            type: 'GET',
            data: {'dox': 'exitInfoPopup'},
            dataType: 'json'
        });
    });
})(jQuery);