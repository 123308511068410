$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            social = header.find(".elm_header_social").clone(),
            nav = header.find(".elm_header_nav").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(social);
        layout_nav.find(".wrp_nav_content").append(nav);
    }

    function initHeadroom(){
        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(selector[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();

            if (win.scrollTop() > 5) {
                selector[0].classList.add("headroom--not-top");
            }
        });
    }

    if (typeof Object.assign !== 'function') {
        $.importScript("https://polyfill.io/v3/polyfill.min.js?features=Object.assign",function () {
            initHeadroom();
        });
    }
    else {
        initHeadroom();
    }
});